import { Routes } from '@angular/router';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';

export const routes: Routes = [
  {
    path: 'mab',
    loadChildren: () =>
      import('./projects/mab/mab.module').then((m) => m.MabModule),
  },
  {
    path: 'dkvform',
    loadChildren: () =>
      import('./projects/dkvergo/dkvergo.module').then((m) => m.DKVErgoModule),
  },
  {
    path: 'ale',
    loadChildren: () =>
      import('./projects/ale/ale.module').then((m) => m.AleModule),
  },
  {
    path: 'agnl',
    loadChildren: () =>
      import('./projects/agnl/agnl.module').then((m) => m.AgnlModule),
  },
  {
    path: 'ag',
    loadChildren: () =>
      import('./projects/ag/ag.module').then((m) => m.AgModule),
  },
  {
    path: 'agaccp',
    loadChildren: () =>
      import('./projects/ag/ag.module').then((m) => m.AgModule),
  },
  {
    path: 'agqual',
    loadChildren: () =>
      import('./projects/ag/ag.module').then((m) => m.AgModule),
  },
  {
    path: 'cora',
    loadChildren: () =>
      import('./projects/cora/cora.module').then((m) => m.CoraModule),
  },
  {
    path: 'rus',
    loadChildren: () =>
      import('./projects/renson/renson.module').then((m) => m.RensonModule),
  },
  {
    path: 'vutg',
    loadChildren: () =>
      import('./projects/vutg/vutg.module').then((m) => m.VutgModule),
  },
  {
    path: 'jeantiteca',
    loadChildren: () =>
      import('./projects/jeantiteca/jeantiteca.module').then(
        (m) => m.JeantitecaModule
      ),
  },
  {
    path: 'q8',
    loadChildren: () =>
      import('./projects/q8/q8.module').then((m) => m.Q8Module),
  },
  {
    path: 'alepluxee',
    loadChildren: () =>
      import('./projects/pluxee/pluxee.module').then((m) => m.PluxeeModule),
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];
